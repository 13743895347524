<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    >
      <v-btn
        slot="right"
        icon
        @click.stop="clickSubmit($refs.form)"
      >
        <v-icon>{{ $vuetify.icons.values.check }}</v-icon>
      </v-btn>
    </b10-toolbar>
    <b10-page-content
      :padding="3"
    >
      <v-form
        ref="form"
        lazy-validation
      >
        <parte-cobrar-form
          :id="routeParams.idparte_trabajo"
          :idempleado="usuarioIdempleado"
          @change-values="changeFormData"
        />
      </v-form>
    </b10-page-content>
  </b10-base>
</template>

<script>
import { formPageMixin } from '@/mixins/formPageMixin'
import ParteCobrarForm from './components/ParteCobrarForm'
import Data from './ParteCobrarData'
import { get } from 'vuex-pathify'

export default {
  components: {
    ParteCobrarForm,
  },
  mixins: [formPageMixin],
  computed: {
    usuarioIdempleado: get('usuario/idempleado'),
    usuarioNombre: get('usuario/nombre'),
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    changeFormData (formData) {
      this.formData = formData
    },
    async loadPage () {
      this.title = `Cobrar parte Nº${this.routeParams.idparte_trabajo}`
    },
    async submitForm () {
      await Data.cobrarParte(this, this.routeParams.idparte_trabajo, this.usuarioNombre, this.formData)
      this.$appRouter.go(-1)
    },
  },
}
</script>
