import _ from '@/utils/lodash'

export default {
  selectFormaPago (Vue) {
    const tables = Vue.$offline.db.tables
    return Vue.$offline.db
      .select()
      .from(tables.forma_pago)
      .innerJoin(
        tables.tforma_pago, tables.forma_pago.idtforma_pago.eq(tables.tforma_pago.idtforma_pago)
      )
      .where(
        Vue.$offline.db.op.and(
          tables.forma_pago.movilidad.eq(true),
          tables.forma_pago.estado.gt(0)
        )
      )
      .orderBy(tables.forma_pago.descripcion)
      .exec()
  },
  async selectCaja (Vue, idformaPago, idempleado) {
    const tables = Vue.$offline.db.tables
    const idtcaja = (
      await Vue.$offline.db
        .select()
        .from(tables.forma_pago)
        .innerJoin(
          tables.tforma_pago, tables.forma_pago.idtforma_pago.eq(tables.tforma_pago.idtforma_pago)
        )
        .where(
          Vue.$offline.db.op.and(
            tables.forma_pago.idforma_pago.eq(idformaPago),
          )
        )
        .exec()
    )[0].tforma_pago.idtcaja
    const whereTcaja = []
    if (idtcaja) {
      whereTcaja.push(tables.caja.idtcaja.eq(idtcaja))
    }
    const cajasEmpleado = await Vue.$offline.db
      .select()
      .from(tables.empleado_caja)
      .where(
        Vue.$offline.db.op.and(
          tables.empleado_caja.idempleado.eq(idempleado),
          tables.empleado_caja.estado.gt(0),
        )
      )
      .exec()
    const inCajas = _.map(cajasEmpleado, 'idcaja')
    return await Vue.$offline.db
      .select()
      .from(tables.caja)
      .innerJoin(
        tables.tcaja, tables.caja.idtcaja.eq(tables.tcaja.idtcaja)
      )
      .where(
        Vue.$offline.db.op.and(
          tables.caja.estado.gt(0),
          tables.caja.idcaja.in(inCajas),
          ...whereTcaja
        )
      )
      .orderBy(tables.caja.descripcion)
      .exec()
  },
}
