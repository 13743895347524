import { v4 as uuidv4 } from 'uuid'
import { currentDate } from '@/utils/date'

export default {
  async cobrarParte (Vue, idparteTrabajo, usuarioNombre, formData) {
    const tables = Vue.$offline.db.tables
    const parteTrabajo = (await Vue.$offline.db
      .select()
      .from(tables.parte_trabajo)
      .innerJoin(
        tables.orden_trabajo,
        tables.parte_trabajo.idorden_trabajo.eq(tables.orden_trabajo.idorden_trabajo)
      )
      .innerJoin(
        tables.sistema,
        tables.orden_trabajo.idsistema.eq(tables.sistema.idsistema)
      )
      .where(
        Vue.$offline.db.op.and(
          tables.parte_trabajo.idparte_trabajo.eq(idparteTrabajo),
        )
      )
      .exec()
    )[0]
    const caja = await Vue.$offline.caja.row(formData.idcaja)
    await Vue.$offline.anticipocli.insertSync({
      idanticipocli: uuidv4(),
      estado: 1,
      idcliente: parteTrabajo.sistema.idcliente,
      idforma_pago: formData.idforma_pago,
      idcaja: formData.idcaja,
      idempresa: caja.caja.idempresa,
      idorden_trabajo: parteTrabajo.parte_trabajo.idorden_trabajo,
      fanticipo: currentDate(),
      importe: formData.importe,
      consumido: false,
      comentario: `Cobro desde movilidad (${usuarioNombre})`,
    })
  },
}
