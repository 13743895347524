<template>
  <b10-base>
    <v-text-field
      v-model.number="form.importe"
      label="Importe"
      clearable
      type="number"
      :rules="formRules.importe"
    />
    <b10-autocomplete
      v-model="form.idforma_pago"
      :items="formasPago"
      item-value="forma_pago.idforma_pago"
      item-text="forma_pago.descripcion"
      label="Forma de pago"
      clearable
      :rules="formRules.idforma_pago"
      @change="formaPagoSelected(form.idforma_pago)"
    />
    <b10-autocomplete
      v-model="form.idcaja"
      :items="cajas"
      item-value="caja.idcaja"
      item-text="caja.descripcion"
      label="Caja"
      clearable
      :rules="formRules.idcaja"
      :disabled="!form.idforma_pago"
    />
  </b10-base>
</template>

<script>
import formMixin from '@/mixins/formMixin'
import Data from './ParteCobrarFormData'
import { calcTotal } from '@/utils/total'
import _ from '@/utils/lodash'

export default {
  mixins: [formMixin],
  props: {
    idempleado: {
      type: Number,
      required: true,
    },
  },
  data () {
    return {
      form: {
        importe: null,
        idforma_pago: null,
      },
      formRules: {
        importe: [
          v => !!v || 'Campo requerido',
          v => parseInt(v) > 0 || 'El importe debe ser mayor a cero',
        ],
        idforma_pago: [
          v => !!v || 'Campo requerido',
        ],
        idcaja: [
          v => !!v || 'Campo requerido',
        ],
      },
      formasPago: [],
      cajas: [],
    }
  },
  async created () {
    this.formasPago = await Data.selectFormaPago(this)
    await this.loadForm()
  },
  methods: {
    async loadFormData (id) {
      const totalesParteTrabajo = calcTotal(await this.$offline.parteTrabajo.selectParteTrabajoTotal(id))
      const parteTrabajo = await this.$offline.parteTrabajo.row(id)
      const totalCobrado = await this.$offline.ordenTrabajo.totalCobrado(parteTrabajo.parte_trabajo.idorden_trabajo)
      this.$set(this.form, 'importe', Number((totalesParteTrabajo.total - totalCobrado).toFixed(2)))
      if (this.formasPago.length === 1) {
        this.$set(this.form, 'idforma_pago', this.formasPago[0].forma_pago.idforma_pago)
        this.formaPagoSelected(this.form.idforma_pago)
      } else if (this.formasPago.length > 1) {
        const ordenTrabajo = await this.$offline.formaPago.FormaDePagoDeParte(id)
        const existeFormaPago = _.find(
          this.formasPago, {
            forma_pago: {
              idforma_pago: ordenTrabajo.forma_pago.idforma_pago
            }
          }
        )
        if (existeFormaPago?.forma_pago.idforma_pago) {
          this.$set(this.form, 'idforma_pago', ordenTrabajo.forma_pago.idforma_pago)
          this.formaPagoSelected(this.form.idforma_pago)
        }
      }
    },
    async formaPagoSelected (idformaPago) {
      if (idformaPago) {
        this.cajas = await Data.selectCaja(this, idformaPago, this.idempleado)
        if (this.cajas.length === 1) {
          this.$set(this.form, 'idcaja', this.cajas[0].caja.idcaja)
        }
      } else {
        this.$set(this.form, 'idcaja', null)
      }
    },
  },
}
</script>
